.leaflet-container {
  border: 1px solid rgba(128, 128, 128, 0.212) !important;
  height: 662px;
}
html {
  overflow-y: scroll;
  scroll-padding-top: 100px;
}

.column {
  float: left;
  width: 20%;
  margin-left: 3%;
  text-align: center;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.filters-wrapper {
  border: 0px solid red !important;
  /*can be usefull. good to have a wrapper*/
}
.filters {
  margin-left: 2%;
  margin-right: 2%;
  padding-bottom: 4%;
  /* border: 3px solid red !important; */
}
.timeline-slider {
  /* border: 2px solid red; */
  margin-left: 10px;
  margin-right: 10px;
}

input[type="checkbox"] {
  position: relative;
  cursor: pointer;
}
.leaflet-control-layers-overlays
  > label:nth-child(1)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color:#85aca2 ;
}
.leaflet-control-layers-overlays > label {
  /* border: 1px solid red; */
  margin-bottom: 0px;
}
.leaflet-control-layers-overlays
  > label:nth-child(2)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color:#d4e2b7 ;
}
.leaflet-control-layers-overlays
  > label:nth-child(3)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color: #f6dea6;
}
.leaflet-control-layers-overlays
  > label:nth-child(4)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color: #db9368;
}
.leaflet-control-layers-overlays
  > label:nth-child(5)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color: #a63a36;
}
.leaflet-control-layers-overlays
  > label:nth-child(6)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color: gray;
}
/* .leaflet-control-layers-overlays
  > label:nth-child(7)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color: black;
}
.leaflet-control-layers-overlays
  > label:nth-child(8)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color: gray;
}
.leaflet-control-layers-overlays
  > label:nth-child(9)
  > span
  > input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 20;
  left: 20;
  margin-right: 30px;
  background-color: white;
  border: 4px solid blue;
} */
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.leaflet-control-layers-overlays
  > label:nth-child(8)
  > span
  > input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid gray;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.leaflet-control-layers-overlays
  > label:nth-child(9)
  > span
  > input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border: solid 0 px rgba(255, 255, 255, 0);
  border-width: 0 0px 0px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.hr-btw-filters {
  display: none;
}
.strong-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  margin-bottom: 0.5em;
  margin-top: 0;
  font-size: 15px;
}

@media (max-width: 560px) {
  .leaflet-bottom {
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    transform: scale(0.9);
    padding-left: 0;
    margin-left: 0;
    /* visibility: hidden; */
    max-width: 200px;
  }

  .team-container {
    /* padding-left: 15%;
  padding-right: 15%; */
    width: 75% !important;
    font-size: small;
    /* height: 100%; */
    margin: 0 auto;
    /* aspect-ratio: 100vh / calc(100vh - 100px - 83px); */
    min-height: calc(100vh - 100px - 83px);
  }

  .tool-desc-container {
    width: 75% !important;
    font-size: small;
    margin: 0 auto;
  }
  .content-container {
    width: 75% !important;
    margin: auto;
    font-size: small;
    min-height: calc(100vh - 100px - 83px - 5px);
  }
  .pull-left {
    float: none !important;
    padding-right: 50%;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .ant-radio-wrapper {
    /* border: solid red 2px; */
    margin-left: 10%;
    width: 100%;
  }
  .strong-text {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    margin-bottom: 0.5em;
    /* margin-left: 10%; */
    padding-top: 10px;
    font-size: 15px;
    text-align: left;
  }

  .hr-btw-filters {
    display: block;
    background-color: #cecece;
    border: none;
    /* border: 2px solid blue; */
  }
}

/* six color green to red */
.row-x {
  display: flex;
}
.column-left {
  flex: 35%;
  padding-left: 250px;
}
.column-mid {
  flex: 8%;
}
.column-right {
  flex: 50%;
}
.ant-menu {
  background-color: #ffffff;
  border-bottom: 0px;
  margin-bottom: 1px;
  margin-top: 25px;
  /* margin-top: 7px; */
}

.ant-menu-item {
  /* border: 2px solid red; */
  margin-right: 0%;
  font-weight: 600;
  font-size: large;
  /* border-radius: 1000px; */
  color: #2060b1 !important;
  border: 10px solid transparent;

  background-color: #ffffff !important;
}

.ant-menu-item-selected {
  /* border: 2px solid red; */
  /* padding-right: 14px; */

  font-weight: 600;
  border: solid 10px #2060b1;
  border-radius: 1000px;
  color: #ffffff !important;
  background-color: #2060b1 !important;
}

.ant-menu-item-active {
  /* border: 2px solid red; */
  /* padding-right: 14px; */
  font-weight: 600;
  border: solid 1px #2060b1;
  border-radius: 1000px;
  /*color: #2060B1 !important;*/
  /* background-color: #2060B1 !important; */
}

.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}

.header-row {
  display: flex;
  justify-content: space-between;
  border-top: 3px solid #2060b1;
  padding-top: 10px;
  border-bottom: 1px solid #c9c9c9;
  background-color: #ffffff;
  /* height: 110px; */
  padding-bottom: 18px;
  font-size: large;
  /* height: 100px; */
}

.header-main-title {
  padding-left: 10%;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ffffff;
  text-align: right;
}

.filters-column {
  /* float: left; */
  /* width: 20%; */
  /* padding-left: 7%;
  padding-right: 7%; */
  text-align: center;
  /* padding-bottom: 0.5%; */
  /* border: 2px solid red; */
}

.header-logo {
  padding-top: 3%;
  padding-bottom: 1%;
  text-align: right;
  margin: auto;
  width: 20%;
  /* color: #2060B1; */
  /* text-align: left; */
}

.team-container {
  /* padding-left: 15%;
  padding-right: 15%; */
  width: 60%;
  /* height: 100%; */
  margin: 0 auto;
  /* aspect-ratio: 100vh / calc(100vh - 100px - 83px); */
  min-height: calc(100vh - 100px - 83px);
}

.tool-desc-container {
  width: 60%;
  margin: 0 auto;
}

.team-card {
  margin-right: 10%;
  margin-bottom: 8%;
  color: #646464;
}

.team-header-wrapper {
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 2rem;
  color: #000;
  position: relative;
  width: 100%;
  display: block;
  font-weight: 700;
}
.team-card-title {
  padding-top: 2%;
  color: #646464;
  padding-bottom: 2%;
  font-weight: 500;
  font-size: 30px;
}
.pull-left {
  float: left;
  padding-right: 50px;
  padding-bottom: 30px;
  padding-top: 30px;
}

.team-summary-title {
  font-weight: 700;
  color: #88451e;
}
.team-summary-title:hover {
  font-weight: 700;
  color: #88451e;
}

.team-summary-card {
  /* margin-right: 10%; */
  margin-bottom: 8%;
  color: #646464;
  /* padding-left: 22%; */
  /* height: 100%; */
  margin: 0 auto;
  margin-bottom: 45px;
  padding-right: 40px;
  /* height: 180px; */
}
.team-summary-wrapper {
  padding-left: 0%;
}

.content-container {
  width: 60%;
  margin: auto;
  min-height: calc(100vh - 100px - 83px - 5px);
}

.anticon-menu {
  color: #2060b1 !important;
}

.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 10px;
  padding-right: 10px;
}

.responsive-ant-menu {
  min-width: 200px;
}
